const env = {
  apiurl: 'https://sayshop-online-api.theecentral.com', //QA
  brandurl: 'https://sayshop-admin.theecentral.com',
  SecretKey: 'SF@Kr&Ar:):)%1610',
}

// dev env

// const env = {
//   apiurl: 'https://sayshop-dev-api.theecentral.com', //QA
//   brandurl: 'https://demo-plus.sayshop.co',
//   SecretKey: 'SF@Kr&Ar:):)%1610',
// }

// qa

// const env = {
//   apiurl: 'https://sayshop-qa-api.theecentral.com', //QA
//   brandurl: 'https://demo-plus.sayshop.co',
//   SecretKey: 'SF@Kr&Ar:):)%1610',
// }

// sandbox environment
// const env = {
//   apiurl: 'https://sayshop-sandbox-api.theecentral.com', //sandbox
//   brandurl: 'https://demo-plus.sayshop.co',
//   SecretKey: 'SF@Kr&Ar:):)%1610',
// }



export default env
