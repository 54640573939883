import React, { useEffect, useState, useContext } from "react";
import UserHeader from "./UserHeader";
// import MobileMenu from './MobileMenu'
import axios from "axios";
import env from "../../enviroinment";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../../App";
import QuantityplusIcon from "../../assets/images/quantityPlus_icon.svg";
import QuantityMinusIcon from "../../assets/images/quantityMinus_icon.svg";
import RupeeIcon from "../../assets/images/bx_rupee_icon.svg";
import NodataImage from "../../assets/images/noData.png";
import Pagination from "../Pagination";
import sampleTest from "../../assets/images/sample_test.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import SearchIcon from "../../assets/images/search_icon.svg";
// import { Carousel } from 'react-responsive-carousel'
// import 'react-responsive-carousel/lib/styles/carousel.min.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BeveragesIcon from "../../assets/images/beverages_icon.svg";
import MojitoIcon from "../../assets/images/mojito_icon.svg";
import juicesIcon from "../../assets/images/juices_icon.svg";
import SnacksIcon from "../../assets/images/snacks_icon.svg";
import SandwichIcon from "../../assets/images/sandwich_icon.svg";
import IcecreamIcon from "../../assets/images/icecream_icon.svg";
import PizzaIcon from "../../assets/images/pizza_icon.svg";
import FaloodaIcon from "../../assets/images/falooda_icon.svg";
import BurgerIcon from "../../assets/images/burger_icon.svg";
import listviewIcon from "../../assets/images/listview_icon.svg";
import GridviewIcon from "../../assets/images/gridview_icon.svg";
import vegIcon from "../../assets/images/veg_icon.svg";
import NonvegIcon from "../../assets/images/nonveg_icon.svg";
import AllCategoryIcon from "../../assets/images/allCategory.svg";
import MobileMenu from './MobileMenu'
import LoadingSpinner from "../loadingSpinner";
const Food = () => {
  const [loading,setLoading]=useState(false);
  const token = sessionStorage.getItem('customerToken')
  // fixed header start
  const [fix, setFix] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 100) {
        setFix(true);
      } else if (window.scrollY === 0) {
        setFix(false);
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll);
  }, [])
  // fixed header end

  // slick caroucel

  var settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 5,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 5,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  // end
  let navigate = useNavigate();
  const searchParams = useParams();
  let [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [query, setQuery] = useState("");
  const [total, setTotal] = useState(0);
  let [selectedItems, setSelectedItems] = useState([]);
  const [foodItems, setFoodItems] = useState([]);
  const [showMore, setShowMore] = useState(true);
  const [add, setAdd] = useState(false);
  const [category, setCategory] = useState(foodItems);
  const [change, setChange] = useState(false);
  const [toggleViewMode, setToggleViewMode] = useState(true);
  const [gridview, setGridView] = useState(false);
  const [activeCategory, setActiveCategory] = useState({
    allCategory: true,
    hotBeverages: false,
    Mojito: false,
    juicesAndShakes: false,
    snacks: false,
    sandwich: false,
    icecream: false,
    pizza: false,
    falooda: false,
    burger: false,
  });
  //pagination states
  // const [currentPage, setCurrentPage] = useState(1)
  // const [postsPerPage] = useState(12)
  // let context = useContext(CartContext)
  // let img = 'https://via.placeholder.com/150'
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  let context = useContext(CartContext);
 
  
  let img = "https://via.placeholder.com/150";
  let loadData = async () => {

    try{
      let businessName = sessionStorage.setItem(
        "businessName",
        searchParams.businessName
      );
      let business = sessionStorage.getItem("businessName");
      setLoading(true);
      let res = await axios.get(`${env.apiurl}/admin/getAdminid/${business}`);
      setLoading(false);
      if (res.data.statusCode === 200) {
        let businessid = res.data._id;
        let companyid = res.data.companyId;
        let parentid = res.data.parentId;
        let userId = sessionStorage.setItem("userId", businessid);
        let companyId = sessionStorage.setItem("companyId", companyid);
        let parentId = sessionStorage.setItem("parentId", parentid);
        sessionStorage.setItem("phoneCode",res.data.phonecode );
        sessionStorage.setItem("currencySymbol",res.data.currencySymbol );
        sessionStorage.setItem("currency",res.data.currency );
  
       
      } else {
      }
      let userId = sessionStorage.getItem("userId");
      setLoading(true);
      let resp = await axios.get(`${env.apiurl}/foods/all-food`, {
        headers: {
          Authorization: `Bearer ${token}`,
          adminid: userId,
        },
      });
      setLoading(false);
      if (res.data.statusCode === 200) {
        let input_array = resp.data.food;
        let result_array = [];
        input_array.map((item) => {
          item.selected_quantity = 0;
          item.selected_quantity_price = 0;
          item.showMore = true;
          item.showIncrementer = false;
          if (context.cart.length > 0) {
  
  
            context.cart.map((prod) => {
              if (prod._id === item._id) {
                item.showIncrementer = true;
                item.selected_quantity = prod.selected_quantity;
                item.selected_quantity_price = prod.selected_quantity_price;
              }
            });
          }
          result_array.push(item);
        });
  
        setFoodItems(result_array);
        if (context.cart.length > 0) {
          console.log("contxt", context.cart);
          setAdd(true)
          setSelectedItems(context.cart);
          let grandTotal = 0;
          context.cart.forEach((element) => {
            grandTotal = grandTotal + element.price * element.selected_quantity;
          });
          setTotal(grandTotal);
        }
      } else {
        // navigate("/login");
      }
    }catch(error){
      setLoading(false);
    }
   
  };
  // **** if selected product id already exist update the array or else insert the object into the array ****
  const upsert = (arr, product) => {
    const i = arr.findIndex((_item) => _item._id === product._id);
    if (i > -1) arr[i] = product;
    else arr.push(product);
  };
  const handleIncrementandDecrement = (e, product, type, index) => {
    let arr = []

    e.preventDefault();
    let data = [];
    let amount = 0;
    if (type === "increase") {
      product.selected_quantity =
        product.quantity > product.selected_quantity
          ? product.selected_quantity + 1
          : product.selected_quantity;
      product.selected_quantity_price =
        product.price * product.selected_quantity;

      let arr = selectedItems;
      upsert(arr, product);
      setSelectedItems(arr);
    } else if (type === "decrease") {
      if (product.selected_quantity <= 1) {
        searchQuery[index].showIncrementer = false;
        const i = selectedItems.findIndex(items => items._id === product._id)
        if (i > -1) selectedItems.splice(i, 1)
      }

      product.selected_quantity =
        product.selected_quantity <= 0
          ? product.selected_quantity
          : product.selected_quantity - 1;
      product.selected_quantity_price =
        product.price * product.selected_quantity;
    }
    console.log(arr);

    // To update selected count and amount
    foodItems.map((item) => {
      let food = item._id === product._id ? product : item;
      data.push(food);
    });
    setFoodItems(data);
    // To update selected count and amount
    context.cart.map((item) => {
      let food = item._id === product._id ? product : item;
      amount += item.selected_quantity_price + item.deliveryFee;
    });
    let grandTotal = 0;
    selectedItems.forEach((element) => {
      grandTotal = grandTotal + element.price * element.selected_quantity;
    });
    console.log("selectedItems",selectedItems);

    setTotal(grandTotal);

    if (selectedItems.length) {
      setAdd(true);
    } else {
      setAdd(false);
    }
  };
  const showAll = (e, index) => {
    searchQuery[index].showMore = false;
    setShow(true);
  };
  const showless = (e, index) => {
    searchQuery[index].showMore = true;
    setShow(false);
  };
  // const deleteNote = (id) => {
  //   setNotes((prevNote) => {
  //     return prevNote.filter((noteItems, index) => {
  //       return index !== id;
  //     });
  //   });
  // };
  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };
  let handleAddProduct = async (e, selectedProds) => {
    e.preventDefault();
    let newArray = [...context.cart];
   
    selectedProds.map((prod) => {
      upsert(newArray, prod);
    });
    context.setCart(newArray);
  
   
   
      if (newArray.length > 0) {
      
        sessionStorage.removeItem('orderId')
        navigate("/checkout");
      } else {
        toast.error("Please Select Atleast One Item");
      }
    ;
    }
    
  const filterResult = (catItem) => {
    setChange(true);
    if (catItem == "all") {
      setCategory(foodItems);
    } else {
      const result = foodItems.filter((curData) => {
        return curData.category === catItem;
      });

      setCategory(result);
    }

    let activecat = {
      allCategory: catItem === "all" ? true : false,
      hotBeverages: catItem === "Hot Beverages" ? true : false,
      Mojito: catItem === "Mojito" ? true : false,
      juicesAndShakes: catItem === "Juices and Shakes" ? true : false,
      snacks: catItem === "Snacks" ? true : false,
      sandwich: catItem === "Sandwich" ? true : false,
      icecream: catItem === "Icecream" ? true : false,
      pizza: catItem === "Pizza" ? true : false,
      falooda: catItem === "Falooda" ? true : false,
      burger: catItem === "Burger" ? true : false,
    };
    setActiveCategory(activecat);
  };

  useEffect(() => {
    loadData();
  }, []);
  useEffect(() => {
    {
      !change && setCategory(foodItems);
    }
  });
  // Get data for pagination
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // const currentProducts = category.slice(indexOfFirstPost, indexOfLastPost)
  const currentProducts = category;
  let searchQuery = currentProducts.filter((products) =>
  
    products.name.toString().toLowerCase().includes(query.toLowerCase())
  );
  

  const listView = () => {
    setToggleViewMode(true);
    setGridView(false);
  };
  const gridView = () => {
    setToggleViewMode(false);
    setGridView(true);
  };
  const handleProductAddButton = (event, product, index) => {
    searchQuery[index].showIncrementer = true;

    setAdd(true)
    if (show) {
      setShow(false);
    } else {
      setShow(true);
    }
  };
  let currencySymbol = sessionStorage.getItem('currencySymbol')
  return (
    <>{loading ? <LoadingSpinner /> : null}
    <div className="bgFitUser">
      <div className="desktopMb24_mobileMb_12">
        <UserHeader
          selectedItems={selectedItems.length}
          onChange={(e) => setQuery(e.target.value)}
        />
      </div>
      <div className="MobileSearch_on">
        <div className={fix ? "sticky_top" : "mobileHeader"}>
          <div className="container">
            <div className="SearchForm">
              <div className="input-group-prepend">
                <span className="inputGroup_text">
                  <img src={SearchIcon} />
                </span>
              </div>
              <input
                type="text"
                placeholder="Search ..."
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container mb_12">
        <Slider {...settings}>
          <div
            className={`${activeCategory.allCategory
              ? "categoryItem active"
              : "categoryItem"
              }`}
            onClick={() => filterResult("all")}
          >
            <div className="categoryFrame">
              <img src={AllCategoryIcon} />
            </div>
            <h5>All</h5>
          </div>

          <div
            className={`${activeCategory.hotBeverages
              ? "categoryItem active"
              : "categoryItem"
              }`}
            onClick={() => filterResult("Hot Beverages")}
          >
            <div className="categoryFrame">
              <img src={BeveragesIcon} />
            </div>
            <h5>Beverages</h5>
          </div>

          <div
            className={`${activeCategory.Mojito ? "categoryItem active" : "categoryItem"
              }`}
            onClick={() => filterResult("Mojito")}
          >
            <div className="categoryFrame">
              <img src={MojitoIcon} />
            </div>
            <h5>Mojito</h5>
          </div>
          <div
            className={`${activeCategory.juicesAndShakes
              ? "categoryItem active"
              : "categoryItem"
              }`}
            onClick={() => filterResult("Juices and Shakes")}
          >
            <div className="categoryFrame">
              <img src={juicesIcon} />
            </div>
            <h5>Juices</h5>
          </div>

          <div
            className={`${activeCategory.snacks ? "categoryItem active" : "categoryItem"
              }`}
            onClick={() => filterResult("Snacks")}
          >
            <div className="categoryFrame">
              <img src={SnacksIcon} />
            </div>
            <h5>Snacks</h5>
          </div>
          <div
            className={`${activeCategory.sandwich ? "categoryItem active" : "categoryItem"
              }`}
            onClick={() => filterResult("Sandwich")}
          >
            <div className="categoryFrame">
              <img src={SandwichIcon} />
            </div>
            <h5>Sandwich</h5>
          </div>
          <div
            className={`${activeCategory.icecream ? "categoryItem active" : "categoryItem"
              }`}
            onClick={() => filterResult("Icecream")}
          >
            <div className="categoryFrame">
              <img src={IcecreamIcon} />
            </div>
            <h5>Icecream</h5>
          </div>
          <div
            className={`${activeCategory.pizza ? "categoryItem active" : "categoryItem"
              }`}
            onClick={() => filterResult("Pizza")}
          >
            <div className="categoryFrame">
              <img src={PizzaIcon} />
            </div>
            <h5>Pizza</h5>
          </div>
          <div
            className={`${activeCategory.falooda ? "categoryItem active" : "categoryItem"
              }`}
            onClick={() => filterResult("Falooda")}
          >
            <div className="categoryFrame">
              <img src={FaloodaIcon} />
            </div>
            <h5>Falooda</h5>
          </div>
          <div
            className={`${activeCategory.burger ? "categoryItem active" : "categoryItem"
              }`}
            onClick={() => filterResult("Burger")}
          >
            <div className="categoryFrame">
              <img src={BurgerIcon} />
            </div>
            <h5>Burger</h5>
          </div>
        </Slider>
      </div>

      <div className="container mb_24">
        <div className="displayFlex alignItem_Center justifyContent_spaceBetween">
          <div>
            <h4 className="Recommended_text">Recommended</h4>
          </div>
          <div className="RecommendedView displayFlex alignItem_Center">
            <div className={toggleViewMode ? "mr_12 ActiveView" : "mr_12"}>
              <img onClick={listView} src={listviewIcon} />
            </div>
            <div className={gridview ? "ActiveView" : null}>
              <img onClick={gridView} src={GridviewIcon} />
            </div>
          </div>
        </div>
      </div>
      <div className="container mobile_padding_0">
        <div className={toggleViewMode ? "productsParent" : null}>
          {toggleViewMode ? (
            searchQuery.length > 0 ? (
              searchQuery.map((product, index) => (
                
                <>
                  <div className="productItem" key={index}>
                    <div className="productContent">
                      {product.vegorNonveg === "veg" ? (
                        <div className="displayFlex alignItem_Center mb_10">
                          <img src={vegIcon} className="mr_10" />
                          <span className="sellerFrame">Bestseller</span>
                        </div>
                      ) : (
                        <div className="displayFlex alignItem_Center mb_10">
                          <img src={NonvegIcon} className="mr_10" />
                          <span className="sellerFrame">Bestseller</span>
                        </div>
                      )}
                      <h5 className="productTitle">{product.name}</h5>
                      <div>
                        <div className="productPrice">
                          {/* <img src={RupeeIcon} /> */}
                          <span>{currencySymbol} {product.price}</span>
                        </div>
                        {product.showMore ? (
                          <div id={index}>
                            <p className="productDescription">
                              {product.description.slice(0, 45)}...
                              <span onClick={(e) => showAll(e, index)}>
                                More
                              </span>
                            </p>
                          </div>
                        ) : (
                          <>
                            <p className="productDescription">
                              {product.description}{" "}
                              <span onClick={(e) => showless(e, index)}>
                                Less
                              </span>
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="productImage">
                      <img
                        className="productItemimg"
                        src={product.image ? product.image : img}
                      />
                      {
                        
                        !product.showIncrementer ? (
                          <button
                          disabled={product.quantity ===0}
                            className="AddButton marginleftRight_auto desktopWidth_160"
                            onClick={(e) => {
                              handleProductAddButton(e, product, index);
                              handleIncrementandDecrement(
                                e,
                                product,
                                "increase",
                                index
                              );
                            }}
                          >
                           {product.quantity===0? "Outofstock":"Add"}
                          </button>
                        ) : (
                          <div className="quantityButton position_absolute bottom_0 marginleftRight_auto desktopWidth_160 leftRight_zero">
                            <button
                              onClick={(e) =>
                                handleIncrementandDecrement(
                                  e,
                                  product,
                                  "decrease",
                                  index
                                )
                              }
                            >
                              <img src={QuantityMinusIcon} />
                            </button>
                            <span>{product.selected_quantity}</span>
                            <button
                              onClick={(e) =>
                                handleIncrementandDecrement(
                                  e,
                                  product,
                                  "increase",
                                  index
                                )
                              }
                            >
                              <img src={QuantityplusIcon} />
                            </button>
                          </div>
                        )}
                        
                      
                    </div>
                  </div>
                  <div className="dashedBorderBottom"></div>
                </>
              ))
            ) : (
              category.length > 0 && (
                <div colspan="6" className="textAlign_center margin_auto">
                  <img src={NodataImage} />
                  <h3 className="nodataTitle">Sorry! No result found :(</h3>
                  <p className="subDescription_text">
                    Sorry we couldn't find any matches for the product
                  </p>
                </div>
              )
            )
          ) : (
            <div className="Grid_view">
              {/* <div className="leftSidevertical"></div> */}
              <div className="row">
                {searchQuery.map((product, index) => (
                  <div className="col-md-4 mb_16" key={index}>
                    <div className="productGridViewItem">
                      <div className="prodGrid_img">
                        <img src={product.image ? product.image : img} />
                        {!product.showIncrementer ? (
                          <button
                            className="AddButton width_120 margin_auto"
                            onClick={(e) => {
                              handleProductAddButton(e, product, index);
                              handleIncrementandDecrement(
                                e,
                                product,
                                "increase",
                                index
                              );
                            }}
                          >
                            Add
                          </button>
                        ) : (
                          <div className="quantityButton position_absolute bottom_0 width_120 margin_auto leftRight_zero">
                            <button
                              onClick={(e) =>
                                handleIncrementandDecrement(
                                  e,
                                  product,
                                  "decrease",
                                  index
                                )
                              }
                            >
                              <img
                                src={QuantityMinusIcon}
                                style={{ width: "20px", height: "20px" }}
                              />
                            </button>
                            <span>{product.selected_quantity}</span>
                            <button
                              onClick={(e) =>
                                handleIncrementandDecrement(
                                  e,
                                  product,
                                  "increase",
                                  index
                                )
                              }
                            >
                              <img
                                src={QuantityplusIcon}
                                style={{ width: "20px", height: "20px" }}
                              />
                            </button>
                          </div>
                        )}
                      </div>
                      <h3 className="prodGridtitle mb_8">{product.name}</h3>
                      <div className="displayFlex alignItem_Center justifyContent_spaceBetween mb_12">
                        <div className="prodGridPrice">
                          {/* <img src={RupeeIcon} /> */}
                          <span>{currencySymbol} {product.price}</span>
                        </div>
                        {product.vegorNonveg === "veg" ? (
                          <div className="displayFlex alignItem_Center">
                            <img src={vegIcon} className="mr_10" />
                            <span className="sellerFrame">Bestseller</span>
                          </div>
                        ) : (
                          <div className="displayFlex alignItem_Center">
                            <img src={NonvegIcon} className="mr_10" />
                            <span className="sellerFrame">Bestseller</span>
                          </div>
                        )}
                      </div>
                      <p className="prodGridtext">{product.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* <div className="mobSpace_mr12 pb_50">
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={foodItems.length}
            paginate={paginate}
          />
        </div> */}
        {!add ? null : (
          <div className="fixedtop_padding">
            <div className="fixedGreenButton displayFlex alignItem_Center justifyContent_spaceBetween width_300 marginleftRight_auto Mobile_bottom51">
              <div className="displayFlex alignItem_Center">
                <div className="mr_8">{selectedItems.length} Items</div>
                {/* <div className="mr_8">|</div> */}
                <div className="displayFlex alignItem_Center">
                  {/* <img src={RupeeIcon} className="width_16 whiteFilderClr" /> */}
                  <span>{currencySymbol} {total}</span>
                </div>
              </div>
              <div>
                <a
                  className="ViewCart_btn"
                  onClick={(e) => handleAddProduct(e, selectedItems)}
                  style={{ cursor: "pointer" }}
                >
                  View Cart
                </a>
              </div>
            </div>
          </div>
        )}

        {token ? <MobileMenu /> : null}
      </div>

      {/* <ToastContainer /> */}
    </div>
    </>
  );
};

export default Food;
